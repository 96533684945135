<template>
  <div class="privitePolicyWrapper">
    <div class="privitePolicyContainer">
      <p class="headerTitle">TERMS AND CONDITIONS</p>
      <section class="paragraph">
        Welcome to
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">
          Boolv.tech.
        </a>
        The Boolv tools are provided by Boolv.tech Ltd. (<span class="fontBold">"Company"</span> or, <span
          class="fontBold">"us"</span>, <span class="fontBold">"our"</span>, <span class="fontBold">"we"</span>) for you
        to create quality
        videos using our tools accessible at the Boolv website located at <a
          href="https://boolv.tech/">https://boolv.tech/</a> and through the designated
        Boolv apps for Android, iOS and Shopify (the Boolv website and apps will be collectively referred to as the
        <span class="fontBold">"Website"</span> ).
      </section>
      <section class="paragraph">These Terms of Service (<span class="fontBold">"Terms"</span>) govern your access and use
        of the Website and all
        services
        available through the Website (<span class="fontBold">"Services"</span>). "You" means any registered Member, any
        adult user of the Website,
        or any parent or guardian of any minor whom you allow to use the Website and the Services, and for whom you will
        be held strictly responsible.</section>
      <section class="paragraph">
        Regularly checking and reviewing this page ensures that you are updated on the terms and conditions governing your
        use of the Services.
      </section>
      <section class="paragraph">
        If we believe that the modifications are material, we will notify you of the changes by posting a notice on our
        Website, or emailing you at the email address provided to us by you, and as we may deem appropriate. What
        constitutes a material change will be determined by us, at our sole and absolute discretion.
      </section>
      <p class="title">AGREEMENT TO TERMS</p>
      <section class="paragraph">
        By using our Services, you agree to be bound by these Terms. If you don't agree to be bound by these Terms, do not
        use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or
        other legal entity, you represent and warrant that you have the authority to bind that company or other legal
        entity to these Terms.
      </section>
      <p class="title">ACCOUNT REGISTRATION</p>

      <section class="paragraph">
        We may allow you to register or otherwise use the Services through third party login services (such as Facebook
        Connect). Whether you register through third party login service or you create a Boolv account on the Website, all
        information provided must be accurate, current and complete. You shall have all responsibility for any
        inaccuracies in any information you provide to us, or in respect of your failure to keep such information
        up-to-date. If you choose to login or
        register through such third party services, you agree to the terms and conditions of all such third party
        services. You acknowledge that the use of third party services to login or register for the Website may provide us
        with access to certain information and data that you have provided to such third party service (according to the
        terms and conditions of such third party service) and you expressly agree to our use of such data or information
        in accordance with these Terms and Privacy Policy.
      </section>
      <section class="paragraph">
        If you do not use a third party login service you will create a Boolv account. Your account allows you to use the
        Services, subject to these Terms and our Privacy Policy. Boolv reserves the right to refuse to allow a user to
        open an account for any reason, or to limit the number of accounts a user can establish on the Website, all at its
        sole discretion. To create an account, you must select a username and password. Your username is your Boolv
        identity. You may not select a username that is used by someone else, and your username cannot be indecent, or
        otherwise offensive, or be used in any way that violates these Terms and common practices. You may not provide
        false information during the registration process.
      </section>
      <section class="paragraph">
        You should not reveal your password to other users. We will not ask you to reveal your password. If you forget
        your password, you can request to have a new password sent to your registered e-mail address. Your account is at
        risk if you let someone use it inappropriately and your account is subject to termination if you or anyone using
        your account violates these Terms. If your account is terminated, you may not open an account again without
        express permission.
      </section>
      <section class="paragraph">
        You agree to immediately notify Boolv of any unauthorized use of your Boolv account or password. You are fully and
        solely responsible for the security of your computer system and all activity on your account, even if such
        activities were not committed by you. Boolv will not be liable for any losses or damage arising from unauthorized
        use of your account or password, and you agree to indemnify and hold Boolv harmless for any improper or illegal
        use of your account, including any mail sent and any charges and taxes incurred, unless you have notified us via
        e-mail to support@boolvector.com that your account has been compromised, and have requested us to block access to
        it. We do not police for, and cannot guarantee that we will learn of or prevent, any inappropriate use of the
        Website.
      </section>
      <!-- // -->
      <section class="paragraph">
        Your Boolv Account on the Website may not be sold or otherwise transferred to another person or entity and is
        limited to a single user per Account, as further described below.
      </section>
      <section class="paragraph">
        If your use of the Website is on behalf of an entity, you represent and warrant that you have the authority to
        bind such entity to the present Terms of Service.
      </section>
      <p class="title">LIMITED LICENSE</p>

      <section class="paragraph">
        Boolv grants you a limited license to access and use the Website and Services subject to these Terms for your
        commercial business use only. You acknowledge that Boolv may, in its sole discretion and at any time, discontinue
        providing any part of the Website or the Services without notice.
      </section>

      <section class="paragraph">
        Use of the Services and access to the Website is void where prohibited. By using the Services, you represent and
        warrant that (a) all registration information you submit is truthful and accurate; (b) you will maintain the
        accuracy of such information; (c) you are 18 years of age or older, have your parent's or guardian's permission to
        enter into this agreement, or otherwise have the ability to form a binding contract; and (d) your use of the
        Website or Services does not violate any applicable law or regulation or any obligation you may have to a third
        party.
      </section>
      <section class="paragraph">
        You are welcome to link to the Website from your website, provided that your website does not imply any
        endorsement by or association with Boolv.
      </section>

      <p class="title">ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION</p>

      <section class="paragraph">
        We are not responsible if the information made available on this Website is not accurate, complete, or current.
        The material on this Website is provided for general information only and should not be relied upon or used as the
        sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources
        of information.
      </section>
      <section class="paragraph">
        We reserve the right to modify the contents of this Website at any time, but we have no obligation to update any
        information on our Website. You agree that it is your responsibility to monitor changes to our Website.
      </section>

      <p class="smallTitle">MODIFICATIONS IN PACKAGE AND PRICE</p>
      <section class="paragraph">
        Prices for the packages listed on our website are subject to change without notice. We reserve the right at any
        time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall
        not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of
        the Packages and Services.
      </section>
      <p class="title">CONTENT</p>

      <section class="paragraph ">
        Certain types of content may be made available through the Website or the Services. "Content" as used in these
        Terms means, collectively, all content on or made available or created on the Website or through the Services by
        you including but not limited to the videos and the music incorporated in it.
      </section>
      <section class="paragraph">You are fully and solely responsible for any Content you create, submit or post to the
        Website or any other platform, including all Content that you include in any video or use to create your videos.
        You represent and warrant that any such submitted Content complies with all applicable law, that you have all
        necessary rights to submit any such Content or to use, display and distribute any submitted Content to create any
        video ad and that no such Content (or modifications you may make to such Content) infringes or violates any third
        party intellectual property rights, privacy or publicity rights, or moral rights. If you submit to Boolv any
        Content from an account at a third party (such as from a Facebook account), you represent and warrant that you
        have all rights necessary to submit such Content for use with the Boolv services. If you distribute (or allow for
        the distribution of) any Content through any third party (such as through a social media account such as
        Facebook), you represent and warrant that you have all rights necessary to distribute (or allow for the
        distribution of) such Content. Boolv has no obligation to accept, display, review or maintain any Content.
        Moreover, Boolv reserves the right to remove and permanently delete any Content, including any Content submitted
        or modified by any user, without notice and for any reason. Moreover, if Boolv reasonably believes that any
        Content distributed through a third party (such as through a social media account such as Facebook) could violate
        any provision of these Terms, Boolv may contact such third party in order to inform such third party of the
        relevant violation.</section>
      <section class="paragraph ">
        WE DO NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT, AND WE EXPRESSLY
        DISCLAIM ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.
      </section>
      <section class="paragraph ">
        Boolv may choose at its sole discretion to monitor Content for inappropriate or illegal behaviour, including
        through automatic means; provided, however, that Boolv reserves the right to treat user Content as content stored
        at the direction of users for which Boolv will not exercise editorial control except when violations are directly
        brought to Boolv's attention. Boolv has the right to edit, delete, distort, modify or move user Content from the
        Website without notice for any reason at any time. Additionally, Boolv has the right to refuse to transmit, email,
        post or broadcast any user Content (including to refuse to allow a user to post or distribute such Content through
        a third party) without notice for any reason at any time.
      </section>
      <section class="paragraph ">
        Content on the Website comes from a variety of sources. You understand that Boolv is not responsible for the
        accuracy, usefulness, safety or intellectual property rights of or relating to this Content. Although users must
        agree to these Terms, it is possible that other users (including unauthorized users) may post or transmit
        offensive or obscene materials that you may be involuntarily exposed to such offensive or obscene materials, and
        you hereby agree to waive and do waive, any legal or equitable rights or remedies you have or may have against us
        with respect to thereto. WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF
        OTHER MEMBERS OR USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING THE USE OF THE
        SERVICE OR OTHERWISE.
      </section>
      <section class="paragraph">
        Content created will be only viewable by you unless you decide to share it publicly on the Website or any other
        platform. PLEASE CAREFULLY SELECT THE TYPE OF INFORMATION THAT YOU INCLUDE IN ANY CONTENT, ESPECIALLY ANY CONTENT
        THAT YOU SHARE PUBLICLY. Do not post publicly any private information or information you do not wish to be seen by
        others, or which you do not want to be shared or edited by others. Please note that once you provide a third party
        with a link to any Content, a such third party may freely share that link with additional third parties. As such,
        please carefully select the individuals to whom you choose to provide links to any Content. We are not
        responsible for the use by third parties of any personal information that you disclose on the Service or through
        any Content.
      </section>
      <section class="paragraph">
        You irrevocably waive all claims that you may have against any Boolv (including its affiliates, employees,
        contractors, users and customers) or any third party entities or individuals for the use or distribution of any of
        your Content including all claims in respect of intellectual property rights, privacy rights, publicity rights or
        moral rights. You shall fully indemnify Boolv against any such claims that you may bring.
      </section>
      <section class="paragraph">
        Please note that the following musical works are no longer available within our Services (as of November 2018):
        Alive; Are You Ready For It; Better All The Time; Blue Danube Waltz; Bout That; Action; Fiesta En El Rancho; Go
        Get The Money; Head Over Heels; How Long; Il Trovatore; Keeping Me Alive; Moonlight Sonata; The Breach; The Theory
        Of Light; Tonight (live Forever); Trumpet Tune; While The City Sleeps; Gymnopedie No. 1.
      </section>
      <p class="title">USE OF THE PLATFORM AND THE SERVICES </p>
      <section class="paragraph">
        You may use the Platform and the Services only for lawful purposes and in accordance with these Terms of
        Conditions.
      </section>
      <section class="paragraph">You hereby agree not to use the Platform and the Services:</section>
      <ul>
        <li class="listItem">In any way that violates any applicable national or international law or regulation.</li>
        <li class="listItem">For the purpose of exploiting, harming, or attempting to exploit any person or harm minors in
          any way by exposing them to inappropriate content or otherwise.</li>
        <li class="listItem">To transmit, or procure the sending of, any advertising or promotional material, including
          any "junk mail",
          "chain letter," "spam," or any other similar solicitation.
        </li>
        <li class="listItem">In any way that infringes upon the rights of others, or in any way is illegal, threatening,
          fraudulent, or
          harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
        </li>
      </ul>
      <section class="paragraph">Additionally, you agree not to:</section>
      <ul>
        <li class="listItem">Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
          malicious or technologically harmful.
        </li>
        <li class="listItem">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
          Service, the server on which the Platform is stored, or any server, computer, or database connected to Service.
        </li>
        <li class="listItem">Engage in abusive usage of the Service. Abusive usage includes, but is not limited to, usage
          that exceeds the normal usage limit, usage that is intended to harm the Platform and the Service, or usage that
          violates any applicable laws or regulations. Any usage of the Service that exceeds 10,000 times for each tool
          may be regarded as suspicious and result in the Service being shifted to a low-speed mode until the usage has
          been verified as normal behavior. Any user found to be engaging in abusive usage of the Service will be subject
          to immediate termination of their license to use the Service.
        </li>
        <li class="listItem">Take any action that may damage or falsify Platform's reputation.
        </li>
        <li class="listItem">In any way decompile, reverse engineer, or disassemble any material or content on the
          Website.
        </li>
        <li class="listItem">Otherwise, attempt to interfere with the proper working of the Platform and the Service.
        </li>
      </ul>
      <p class="title">PRIVACY AND USAGE OF COOKIES</p>
      <section class="paragraph">The company will not intentionally disclose any personally identifying information about
        you to third parties, except where Company, in good faith, believes such disclosure is necessary to comply with
        the law or enforce these Terms. By using the Website, you signify your acceptance of the Privacy policy.</section>
      <section class="paragraph">Members signing up for the Website are opting in to receive newsletters and other special
        offers through emails/notifications from the Website. If you do not wish to receive these emails, you may opt out
        anytime by unsubscribing.</section>
      <section class="paragraph">Refer to our <router-link to="/privacy">Privacy Policy</router-link> and <router-link
          to="/cookies">Cookie
          Policy</router-link>.
      </section>
      <p class="title">NO SPAM POLICY</p>
      <section class="paragraph">You understand and agree that sending unsolicited email advertisements to Company's email
        addresses or through Our computer systems, which is expressly prohibited by these Terms, will use or cause to be
        used servers located in California. Any unauthorized use of Our computer systems is a violation of these Terms and
        certain federal and state laws, including without limitation, the Computer Fraud and Abuse Act (18 U.S.C. § 1030
        et seq.), Section 502 of the California Penal Code and Section 17538.45 of the California Business and Professions
        Code.</section>
      <section class="paragraph">Such violations may subject the sender and his or her agents to civil and criminal
        penalties.</section>
      <p class="title">INTELLECTUAL PROPERTY RIGHTS</p>
      <section class="paragraph">"Intellectual Property Rights" means any and all rights existing from time to time under
        patent law, copyright law, moral rights law, trade secret law, trade mark law, unfair competition law, publicity
        rights law, privacy rights law, and any and all other proprietary rights, as well as, any and all applications,
        renewals, extensions, restorations and reinstatements thereof, now or hereafter in force and effect worldwide.
      </section>
      <section class="paragraph">All material and content on the Website, including images, illustrations, text, graphics,
        logos, button icons, images, audio clips, digital downloads, data compilations and software, is our property, or
        the property of our affiliates or content suppliers, and is protected by the domestic as well as international
        intellectual property law, including copyright, authors' rights, database rights laws, trademarks, and other
        intellectual property rights that are owned and controlled by us or by other parties that have licensed their
        material to us.</section>
      <section class="paragraph">The compilation of all content on the Website is our exclusive property and is protected
        by domestic as well as international copyright and database rights laws.</section>
      <section class="paragraph">You hereby agree to not copy, reproduce, republish, upload, post, transmit or distribute
        such material in any way, including by e-mail or other electronic means whether directly or indirectly and you
        must not assist any other person to do so. Without the prior written consent of the owner, modification of the
        materials, use of the materials on any other Website or use of the materials for any purpose other than personal,
        non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited.
      </section>
      <p class="title">INDEMNIFICATION</p>
      <section class="paragraph">You understand and agree that you are personally responsible for your behaviour on the
        Website. You agree to indemnify, defend and hold Company harmless from and against all claims, losses, expenses,
        damages and costs (including, but not limited to, direct, incidental, consequential, exemplary and indirect
        damages), and reasonable attorneys' fees, resulting from or arising out of
        your use, misuse, or inability to use the Website or the Content, or any violation by you of these Terms.
      </section>
      <p class="title">DISCLAIMER OF WARRANTY</p>
      <section class="paragraph">Your use of the Website, Content and Services is at your sole discretion and risk. The
        Website, Content and Services, and any product made available through the foregoing, are provided on an "as is"
        and "as available" basis without warranties of any kind.
      </section>
      <section class="paragraph">WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING
        TO THE SITE, CONTENT, AND SERVICES, OR WITH RESPECT TO ANY PRODUCT MADE AVAILABLE THROUGH THE FOREGOING, INCLUDING
        WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
        PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED,
        (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE, CONTENT AND SERVICES;
        OR (II) THAT THE SITE AND SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE
        PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED BY THE SITE
        AND SERVICES.
      </section>
      <section class="paragraph">
        We are not responsible for any incorrect or inaccurate Content posted on the Website or in connection with the
        Services, or transmitted by any User, whether by users of the Services or by any of the equipment or programming
        associated with the Services. We take no responsibility for third party advertisements which are posted on this
        Website or through the Services, nor does it take any responsibility for the goods or services provided by its
        advertisers. No advice or information, whether oral or written, obtained by you from us, shall create any warranty
        not expressly stated in these Terms. If you choose to rely on such information, you do so solely at your own risk.
        Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above
        exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion
        of implied warranties.
      </section>
      <section class="paragraph">PRIOR TO UPLOADING ANY CONTENT TO THE SITE, YOU SHOULD ENSURE THAT YOU FULLY UNDERSTAND
        HOW THIRD PARTIES MAY ACCESS AND SHARE, SUCH CONTENT. IF YOU DO NOT FULLY UNDERSTAND HOW THIRD PARTIES MAY ACCESS
        AND SHARE CONTENT, DO NOT UPLOAD CONTENT. YOU SHALL NOT HAVE ANY CLAIMS AGAINST BOOLV, AND YOU HEREBY IRREVOCABLY
        WAIVE ALL CLAIMS AGAINST BOOLV, IN RESPECT OF THE USE, EDITING, MODIFICATION OR DISTRIBUTION OF ANY CONTENT (A)
        DESIGNATED AS "PUBLIC" ON THE SITE OR (B) IN RESPECT OF WHICH YOU HAVE PROVIDED THIRD PARTIES WITH A LINK TO SUCH
        CONTENT. BOOLV DOES NOT ASSUME ANY RESPONSIBILITY WITH RESPECT TO WHETHER USER GENERATED CONTENT INFRINGES THE
        INTELLECTUAL PROPERTY, MORAL, PRIVACY OR PUBLICITY RIGHTS OF ANY THIRD PARTY.
      </section>
      <section class="paragraph">Boolv is not a storage service. You agree that we have no obligation to store, maintain
        or provide you with a copy of any Content or information that you provide, except to the extent required by
        applicable law.
      </section>
      <p class="title">LIMITATION OF LIABILITY </p>
      <section class="paragraph">We assume no responsibility for any error, omission, interruption, deletion, defect,
        delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to,
        or alteration of, any Content or Services. We are not responsible for any problems or technical malfunction of any
        telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure
        of any email due to technical problems or traffic congestion on the Internet or on any of the Website or Services
        or combination thereof, including any injury or damage to users or to any person's computer related to or
        resulting from participation or downloading materials in connection with the Website or Services. Under no
        circumstances shall we be responsible for any loss or damage, including personal injury or death, resulting from
        use of the Website or Services, from any Content posted on or through the Website or Services, or from the conduct
        of any Users of the Website or Services, whether online or offline.</section>
      <section class="paragraph">IN NO EVENT SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE, CONTENT AND SERVICES, INCLUDING
        BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SITE OR
        THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. EXCEPT WITH RESPECT TO THE PURCHASE OF PHYSICAL GOODS, THE FOREGOING LIMITATION OF LIABILITY SHALL
        APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR CUMULATIVE
        LIABILITY TO YOU EXCEED U.S. $100. YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY PURCHASED GOODS WILL BE THE
        REFUND BY BOOLV OF THE AMOUNT YOU PAID FOR SUCH PRODUCT.
      </section>
      <p class="title">LINKED CONTENT </p>
      <section class="paragraph">
        Portions of the Website (including, without limitation, advertisements) may involve linking to or using websites
        belonging to third parties. The Website may also provide you with links to access the Websites of third party
        vendors or retailers including, without limitation, for the purpose of reviewing or purchasing items. We have no
        control over third-party sites, and all use of third-party sites is at your own risk. Additionally, Boolv cannot
        accept responsibility for any payments processed or submitted through such third party sites, or for the privacy
        policies of any such sites. Boolv is not responsible for content or services available by means of such sites.
        Boolv does not endorse any products or services offered by third parties and we urge our users to exercise caution
        in using third-party sites. Boolv may allow you to purchase physical goods from third parties. Boolv has no
        responsibility or liability in respect of the provision of goods or services from any third party.
      </section>

      <p class="title">ELIGIBILITY</p>
      <section class="paragraph">
        In order to use the Platform, You need to be 18 (eighteen) years of age or older. The Platform may only be used or
        accessed by such Persons who can enter into and perform legally binding contracts under the applicable state and
        federal laws. The Company shall not be liable in case of any false information is provided by the User including
        the User's age and the User and/or his natural or appointed guardian alone shall be liable for the consequences as
        per the applicable state and federal laws. The Company disclaims all liability arising out of such unauthorised
        use of the Platform and any third-party liability arising out of Your use of the Platform if You are a minor.
      </section>

      <p class="title">GOVERNING LAW AND JURISDICTION</p>
      <section class="paragraph">
        These Terms and any separate agreements whereby we provide you Services shall be governed by and construed in all
        respects in accordance with the Laws of Wyoming, United States of America and shall have exclusive jurisdiction
        over any dispute arising under this Agreement.
      </section>

      <p class="title">NOTICES</p>
      <section class="paragraph">
        Legal notices must be served on the email address provided in the 'Contact Us' clause. Notice will be deemed given
        48 hours after the email is sent unless the sending party is notified that the email address is invalid or that
        the email has not been delivered. Alternatively, we may give you legal notice by mail to the address provided by
        you during the registration process. In such case, notice will be deemed given three days after the date of
        mailing.
      </section>
      <p class="title">LEGAL DISPUTES</p>
      <section class="paragraph">
        If a dispute arises between you and Company, our goal is to provide you with a neutral and cost-effective means of
        resolving the dispute quickly. We strongly encourage you to first contact us to seek a resolution. If your dispute
        is not resolved by contacting us, all legal notices and formal disputes should be sent to Company's email id in
        accordance with the above-mentioned Clause. We agree to consider resolving the dispute through alternative dispute
        resolution procedures, such as mediation or arbitration, as alternatives to litigation in accordance with the Laws
        of Wyoming, United States of America.
      </section>
      <section class="paragraph">The Place for alternative dispute resolution is in Wyoming, United States of America.
      </section>
      <p class="title">REMOVAL OF DOUBTS</p>
      <section class="paragraph">
        Notwithstanding anything stated in this Regulation for any unforeseen issues arising, and not covered by this
        regulation, or in the event of differences of interpretation, the CEO of the Company may take a decision as he/she
        may deem fit. The decision of the CEO shall be final.
      </section>
      <p class="title">ONLINE APPLICATION STORES</p>
      <section class="paragraph">
        Your use of the Services through our smartphone apps may be subject to additional third party terms and conditions
        that govern that application marketplace from which you downloaded the App, such as AppStore or Google Play. Such
        third parties are not responsible for providing maintenance and support services with respect to the Website.
      </section>
      <section class="paragraph">
        The following terms apply if you downloaded the Website's iOS app from Apple's App Store. You and us agree and
        acknowledge as follows:
      </section>
      <section class="paragraph">
        These Terms are concluded between yourself and us, and not with Apple Inc. ("Apple"). Apple is not responsible for
        the Services. In the event of a conflict between these Terms and the App Store Terms of Service then the App Store
        Terms of Service will prevail, solely with respect to the conflicting provisions.
      </section>
      <section class="paragraph">
        The license granted to you for the Services' app is limited to a non-transferrable license to use the app on any
        iOS Products that you own or control, and as permitted by the Usage Rules set forth in the App Store Terms of
        Service, except that such App may be accessed, acquired, and used by other accounts associated with the purchaser
        via Family Sharing.
      </section>
      <section class="paragraph">
        In the event of a failure to conform to any applicable warranty (if any warranty is applicable), you may notify
        Apple, and Apple will refund the purchase price for the smartphone app to you (if you paid any). Apple has no
        other warranty obligation whatsoever with respect to the app, and any other claims, losses, liabilities, damages,
        costs or expenses attributable to any failure to conform to any warranty, will not be at Apple's responsibility.
        Apple is not responsible for addressing any claims by you or any third party relating to the Services or your
        possession and/or use of the Services, including (i) product liability claims, (ii) any claim that the Services
        fails to conform to any applicable legal or regulatory requirement, and (iii) claims arising under consumer
        protection or
        similar legislation.
      </section>
      <section class="paragraph">
        In the event of any third party claim that the app or your possession and use of the app infringes that third
        party's IP Rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any
        such infringement claim.
      </section>
      <section class="paragraph">
        You must comply with applicable third party terms of agreement when using the app (e.g. you must not be in
        violation of your wireless data services agreement when you use the app). Apple and Apple's subsidiaries are third
        party beneficiaries of these Terms. Upon your acceptance of these Terms, Apple will have the right (and will be
        deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof.
      </section>
      <section class="paragraph">
        You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo,
        or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not
        listed on any U.S. Government list of prohibited or restricted parties.
      </section>

      <p class="title">MISCELLANEOUS
      </p>
      <section class="paragraph">
        <span class="fontBold" style="color: #000000;"> Severability</span> - The provision or part-provision of this
        Agreement is or
        becomes
        invalid, illegal or
        unenforceable,
        it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such
        modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification
        to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability
        of the rest of this, agreement. If any provision or part-provision of this Agreement is invalid, illegal or
        unenforceable, the parties shall negotiate in good faith to amend such provision so that as amended, It is legal,
        valid and enforceable, and, to the greatest extent possible, achieves the intended commercial result of the
        original provision."
      </section>
      <section class="paragraph">
        <span class="fontBold" style="color: #000000;">Entire Agreement</span> - The failure of us to exercise or enforce
        any right or
        provision of these Terms and Conditions shall not constitute a waiver of such right or provision. These Terms and
        Conditions and any policies or operating rules posted by us on this site or in respect to the Service constitute
        the entire agreement and understanding between you and us and govern your
        use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether
        oral or written, between you and us.Any ambiguities in the interpretation of these Terms and Conditions shall not
        be construed against the drafting party.
      </section>
      <section class="paragraph">
        <span class="fontBold" style="color: #000000;">Waiver</span> - If you breach these Terms and we take no action, we
        will still be
        entitled to use our rights and remedies in any other situation where you breach these Terms.
      </section>
      <section class="paragraph">
        <span class="fontBold" style="color: #000000;">Amendments</span> - Notwithstanding anything contained
        hereinbefore, The Company may
        amend and implement the Terms, whenever required, in the interest of maintaining the standard and improving user
        experience without any prior notice and you shall be governed by such Terms so implemented from time to time.
        Please review the Terms from time to time on a regular basis since your ongoing use is subject to the Terms as
        amended.
      </section>
      <section class="paragraph">
        <span class="fontBold" style="color: #000000;">Force Majeure</span> - No one shall be liable for any delay or
        failure in
        performance due to events outside the defaulting Party's reasonable control, including without limitation acts of
        God, earthquake, riots, war, acts of terrorism, fire, epidemics, or delays of common carriers or other
        circumstances beyond its reasonable control.
      </section>
      <p class="title">TERMINATION OF ACCOUNT</p>
      <section class="paragraph">
        You agree that Boolv may for any reason, in its sole discretion and without notice, terminate your account, and
        remove from the Website any Content associated with your account. Grounds for such termination may include (i)
        extended periods of inactivity, (ii) violation of the letter or spirit of these Terms, (iii) fraudulent, harassing
        or abusive behavior, or (iv) behavior that is harmful to other users, third parties, or the business interests of
        Boolv.
      </section>
      <section class="paragraph">
        Termination of Account for any of the above mentioned reasons shall not entitle you to any refund.
      </section>
      <section class="paragraph">
        If Boolv believes, in its sole discretion, that a violation of these Terms or any illegal or inappropriate
        behavior has occurred, we may take any other corrective action we deem appropriate. We reserve the right to
        investigate suspected violations of these Terms or illegal and inappropriate behavior on the Website. We will
        fully cooperate with any law enforcement investigation or court order requesting or directing us to disclose the
        identity, behavior or Content of anyone believed to have violated these Terms or to have engaged in illegal
        behavior.
      </section>
      <section class="paragraph">
        If you want to terminate your and account subscription permanently, please log in and go to the "My Account page
        on and confirm the "Delete Account" (but you may not do so while your subscription is suspended).
      </section>
      <section class="paragraph">
        Any suspension or termination of your account shall not affect your obligations under these Terms (including but
        not limited to ownership, indemnification, any representations and warranties made by you, and limitation of
        liability), which by their sense and context are intended to survive such suspension or termination. On
        termination, you lose the right to access or use the Services.
      </section>
      <p class="title">CONTACT US</p>
      <section class="paragraph">
        You may direct questions concerning these Terms and Conditions by email to <a
          href="mailto: support@boolvector.com" target="_blank">support@boolvector.com</a>
        by adding the
        word "Terms" in the subject line.
      </section>
      <section class="paragraph">
        Last Updated: February 17, 2023
      </section>
    </div>
  </div>
</template>
<script setup>

import { useSideStore } from '@/store'
const userStore = useSideStore()
window.addEventListener("scroll", () => {
  userStore.isCollapse = true
});
</script>
<style scoped lang="less">
.privitePolicyWrapper {
  min-width: 720px;
  padding: 140px 0;
  display: flex;
  justify-content: center;
  font-family: 'Inter', Arial;
  text-align: left;
}

.privitePolicyContainer {
  max-width: 1250px;
  width: 90%;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: normal;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  padding-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 26px;
  color: #000000;
  padding-bottom: 24px;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 12px;
}

.table {

  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.listItem {
  padding-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}

ul {
  list-style: none
}
</style>